import { withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createBlock as _createBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/icons/delete-image.svg'


const _withScopeId = n => (_pushScopeId("data-v-226b7b22"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["image-item--wrapper", {active: $props.isActive}])
  }, [
    ($props.imageItem?.link)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          ($props.withDelete)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                class: "image-item--delete",
                src: _imports_0,
                alt: "Delete Image Button",
                role: "button",
                onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('deleteImage', $props.imageItem)), ["stop","prevent"]))
              }))
            : _createCommentVNode("", true),
          _createElementVNode("img", {
            class: "image-item",
            src: $props.imageItem.link,
            alt: "Image"
          }, null, 8, _hoisted_1)
        ], 64))
      : (_openBlock(), _createBlock($setup["Loader"], {
          key: 1,
          class: "image-item--loader",
          size: 24,
          loading: ""
        }))
  ], 2))
}