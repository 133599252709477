import { FileEntityInterface } from "@/types/FileEntity";

export interface ModelEntityInterface extends FileEntityInterface {
  convertedUploadId: {
    id: string;
    filename: string;
    link: string;
    status: string;
  };
  previewUploadId: {
    id: string;
    filename: string;
    link: string;
    status: string;
  };
  metadata?: {
    name: string;
    metadataVersion: 2;
    parts: Record<string, PartV2Interface>;
    solids: Record<string, SolidInterface>;
  };
}

export class ModelEntityClass implements ModelEntityInterface {
  id = "";
  rfqUploadId = "";
  originalUploadId = {
    id: "",
    filename: "",
    status: "",
    link: "",
  };
  convertedUploadId = {
    id: "",
    filename: "",
    link: "",
    status: "",
  };
  previewUploadId = {
    id: "",
    filename: "",
    link: "",
    status: "",
  };
  userId = {
    id: "",
    firstName: "",
    lastName: "",
  };
  createdAt = "";
  updatedAt = "";
}
export default interface CostingMachiningDirectionInterface {
  id: string;
  createdAt: string;
  updatedAt: string;
  administrativeProcessId: string;
  costingId: string;
  processName: string;
  unitLeadTime: string;
  unitCost: string;
  batchLeadTime: string;
  batchCost: string;
  mfrRegionId: string;
}

interface VectorInterface {
  x: number;
  y: number;
  z: number;
}

interface TransformationInterface {
  scale: number;
  rotation: VectorInterface;
  translation: VectorInterface;
}

type BoundingBox = VectorInterface & {
  min: VectorInterface;
  max: VectorInterface;
  transformation: TransformationInterface;
};

interface PartInterface {
  area: number;
  volume: number;
  boundingBox: BoundingBox;
}

type PartV2Interface = PartInterface & {
  name: string;
  solids: string[];
};

export interface SolidInterface {
  shapes: {
    group: "face";
    id: number;
    type: "face" | "plane";
    location: VectorInterface;
    normal: VectorInterface;
  };
}
