import { AxiosResponse } from "axios";
import ApiService from "@/services";
import { AddPartFeedbackPayload } from "@/types/api/AddPartFeedbackPayload";

export default class ThreadsAttachmentsService {
  static addPartFeedbackImages(
    payload: { attachmentId: string; threadId: string }[]
  ): Promise<AxiosResponse> {
    return ApiService.post("/v1/threads/attachments", payload);
  }
  static getPartFeedbackImages(params: {
    threadId: string;
  }): Promise<AxiosResponse> {
    return ApiService.get("/v1/threads/attachments", { params });
  }
  static deletePartFeedbackImage(attachmentId: string): Promise<AxiosResponse> {
    return ApiService.delete(`/v1/threads/attachments/${attachmentId}`);
  }

  static addPartFeedbackMessageImages(
    payload: { attachmentId: string; threadMessageId: string }[]
  ): Promise<AxiosResponse> {
    return ApiService.post("/v1/threads/message-attachments", payload);
  }
  static getPartFeedbackMessageImages(params: {
    threadMessageId: string;
  }): Promise<AxiosResponse> {
    return ApiService.get("/v1/threads/message-attachments", { params });
  }
  static deletePartFeedbackMessageImage(
    attachmentId: string
  ): Promise<AxiosResponse> {
    return ApiService.delete(`/v1/threads/message-attachments/${attachmentId}`);
  }
}
